import { useCallback, useRef, useState } from 'react'
import {
  Button,
  Fa,
  FilterValueType,
  Table,
  showErrorMessage,
} from '@eltoro-ui/components'
import { Billingservicev2Invoice } from 'next-gen-sdk'
import { dayjs } from 'Tools/dateUtils'
import { EmptyField, InfoPopover, TablePagination } from 'Components'
import { useAppContext } from 'Contexts'
import { billingInvoiceStatusOptions } from 'searchOptions'
import { InvoiceStatusIndicator } from '../InvoiceStatusIndicator'
import { InvoiceStatusPopover } from '../InvoiceStatusPopover'
import { InvoicePaymentModal } from '../InvoicePaymentModal'
import { downloadInvoicePDF } from 'Requests'
import { useDebounce, useTokenPagination } from '@eltoro-ui/hooks'
import { isOrgBillable } from 'Helpers'
import classNames from 'classnames'

export const InvoiceTable = ({
  handleReset,
}: {
  handleReset: (type: 'credit-cards' | 'balance') => void // for resetting balance in parent component
}) => {
  const [downloading, setDownloading] = useState(false)
  const [invoiceToPay, setInvoiceToPay] = useState<Billingservicev2Invoice>()
  const [filter, setFilter] = useState<{
    [field: string]: Array<string | number>
  }>()
  const [sort, setSort] = useState<string>() // field asc, field desc
  const { currentOrg, billingServiceApi } = useAppContext()
  const debouncedFilter = useDebounce(filter, 200)
  const isMounted = useRef(false)

  const handleFetchOrgInvoices = useCallback(
    async ({
      pageSize,
      nextPageToken,
    }: {
      pageSize: number
      nextPageToken?: string
    }) => {
      const empty = {
        data: [],
        totalItems: 0,
      }
      if (
        billingServiceApi &&
        currentOrg?.id &&
        isOrgBillable(currentOrg) &&
        isMounted.current
      ) {
        /*
        InvoiceStatus_INVOICE_STATUS_UNKNOWN        InvoiceStatus = 0
        InvoiceStatus_INVOICE_STATUS_PAID           InvoiceStatus = 1
        InvoiceStatus_INVOICE_STATUS_PROCESSING     InvoiceStatus = 2
        InvoiceStatus_INVOICE_STATUS_DECLINED       InvoiceStatus = 3
        InvoiceStatus_INVOICE_STATUS_PAYMENT_DUE    InvoiceStatus = 4
        InvoiceStatus_INVOICE_STATUS_PARTIALLY_PAID InvoiceStatus = 5
        InvoiceStatus_INVOICE_STATUS_PAST_DUE       InvoiceStatus = 6
      */
        const res = await billingServiceApi
          .advertisingPlatformServiceListInvoicesV2(
            currentOrg.id,
            pageSize, // pageSize
            nextPageToken, // pageToken
            sort ? sort : 'status desc', // orderBy (sort)
            `org_id = "${currentOrg.id}"${
              (debouncedFilter?.['status'] || []).length > 0 ? ' AND ' : ''
            } ${(debouncedFilter?.['status'] || [])
              ?.map((status) => `status = ${status}`)
              .join(' OR ')}`, // filter
          )
          .catch(() => {
            showErrorMessage('Error getting invoices', '')
            return { invoices: [], totalSize: 0, nextPageToken: '' }
          })
        if (res && res?.invoices) {
          return {
            data: res.invoices || [],
            totalItems: res.totalSize || 0,
            nextPageToken: res.nextPageToken,
          }
        }
      } else {
        isMounted.current = true
      }
      return empty
    },
    [billingServiceApi, currentOrg, debouncedFilter, sort],
  )

  const pagination = useTokenPagination<Billingservicev2Invoice>(
    {
      pageSize: 10,
      fetchData: handleFetchOrgInvoices,
    },
    [currentOrg?.id, sort, debouncedFilter],
  )
  const handleSort = (
    _: number,
    newSort?: 'asc' | 'desc',
    path?: string | string[],
  ) => {
    if ((!newSort || !path) && (sort === 'status desc' || sort === undefined))
      return
    if (!newSort || !path) {
      setSort('status desc')
    } else {
      setSort(`${path}${newSort === 'desc' ? ` ${newSort}` : ''}`)
    }
  }

  const handleFilter = (
    _: number,
    path: string | string[],
    filterOn: FilterValueType,
  ) => {
    const key = typeof path === 'object' ? path.join('.') : path
    setFilter((prev) => {
      return { ...prev, [key]: filterOn }
    })
  }

  const { loading, currentPageData: invoices, refetch } = pagination

  return (
    <>
      <div className="InvoiceTable">
        <div
          className={`InvoiceTable__loading-wrapper${
            loading && invoices.length !== 0
              ? 'pointer-events-none opacity-50'
              : ''
          }`}
        >
          <Table
            className={classNames('animate-fadein', {
              'mb-0 overflow-hidden': invoices.length === 0,
            })}
            columns={[
              {
                path: 'recordId',
                label: 'Invoice Number',
                removeFilter: true,
              },
              {
                path: 'total',
                label: 'Amount',
                removeFilter: true,
                RowCell: (invoice) => <>{`$${invoice.total?.toFixed(2)}`}</>,
              },
              {
                path: 'status',
                filterOn: billingInvoiceStatusOptions,
                label: (
                  <div className="flex items-center">
                    Status <InfoPopover content={<InvoiceStatusPopover />} />
                  </div>
                ),
                RowCell: (invoice) =>
                  invoice.status ? (
                    <InvoiceStatusIndicator status={invoice.status} />
                  ) : (
                    <EmptyField />
                  ),
              },
              {
                path: '',
                label: '',
                removeFilter: true,
                removeSort: true,
                RowCell: (invoice) => (
                  <span
                    className="flex items-center justify-center"
                    aria-label={(() => {
                      if ((invoice.totalDue || 0) < 0)
                        return 'This invoice is a credit'
                    })()}
                    data-tooltip="top"
                  >
                    {invoice.status === 'INVOICE_STATUS_PAID' ? (
                      <EmptyField />
                    ) : (
                      <Button
                        className="ButtonUrgent"
                        size="s"
                        disabled={(invoice.totalDue || 0) < 0}
                        onClick={() => setInvoiceToPay(invoice)}
                      >
                        Pay now
                      </Button>
                    )}
                  </span>
                ),
              },
              {
                path: 'when_due',
                label: 'Due',
                removeFilter: true,
                RowCell: (invoice) => {
                  if (!invoice.whenDue) return <EmptyField />
                  const isPastDue =
                    dayjs.tz(invoice.whenDue).isBefore(dayjs().tz(), 'day') &&
                    invoice.status !== 'INVOICE_STATUS_PAID'
                  if (isPastDue) {
                    return (
                      <div className="flex items-center gap-2">
                        <span
                          aria-label="This invoice is past due"
                          data-tooltip="top"
                        >
                          <Fa
                            icon="calendar-exclamation"
                            size={1}
                            color="#e2bb37"
                          />
                        </span>
                        {dayjs.tz(invoice.whenDue).format('MM/DD/YYYY')}
                      </div>
                    )
                  }
                  return dayjs.tz(invoice.whenDue).format('MM/DD/YYYY')
                },
              },
              {
                path: 'applied_amount',
                label: 'Applied Amount',
                removeFilter: true,
                RowCell: (invoice) =>
                  `$${invoice.appliedAmount?.toFixed(2) || 0}`,
              },
              {
                path: 'total_due',
                label: 'Balance',
                removeFilter: true,
                RowCell: (invoice) => `$${invoice.totalDue?.toFixed(2) || 0}`,
              },
              {
                path: '',
                label: 'Download',
                removeFilter: true,
                RowCell: (invoice) => (
                  <Button
                    size="s"
                    onClick={() => {
                      if (invoice.downloadKey && currentOrg?.id) {
                        setDownloading(true)
                        downloadInvoicePDF(invoice.downloadKey, currentOrg.id)
                          .catch(() =>
                            showErrorMessage(
                              'Download Error',
                              'There was an error downloading your invoice.',
                            ),
                          )
                          .finally(() => setDownloading(false))
                      }
                    }}
                    disabled={!invoice.id || downloading}
                    iconOnly={<Fa icon="download" size="sm" />}
                  />
                ),
              },
            ]}
            rows={invoices}
            onSort={handleSort}
            onFilter={handleFilter}
          />
          {(() => {
            if (loading && invoices.length === 0)
              return (
                <div className="InvoiceTable__loading animate-pulse text-center text-lg">
                  <Fa
                    icon="magnifying-glass"
                    size={5}
                    type="duotone"
                    className="text-primary-200"
                  />
                  <p className="text-grey-600 font-bold">Loading invoices.</p>
                </div>
              )
            if (!invoices.length)
              return (
                <div className="InvoiceTable__empty animate-fadein text-center text-lg">
                  <Fa
                    icon="magnifying-glass"
                    size={5}
                    type="duotone"
                    className="text-primary-200"
                  />
                  <p className="text-grey-600 font-bold">Found no results.</p>
                </div>
              )
            return <TablePagination pagination={pagination} />
          })()}
        </div>
      </div>
      {invoiceToPay && (
        <InvoicePaymentModal
          invoice={invoiceToPay}
          handleClose={() => setInvoiceToPay(undefined)}
          handleReset={() => {
            handleReset('balance')
            refetch()
          }}
        />
      )}
    </>
  )
}
