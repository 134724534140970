import { ReactNode, useContext, useEffect, useState } from 'react'
import { Avatar, Button, Fa, showErrorMessage } from '@eltoro-ui/components'
import { Orgmanagerv1Org } from 'next-gen-sdk'
import { Link, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import { ThemeContext, useAppContext } from 'Contexts'
import { truncateMiddle } from 'Helpers'
import { SectionCollapse } from '../SectionCollapse'
import { useTokenPagination } from '@eltoro-ui/hooks'
import { CopyButton, TablePagination } from 'Components'

const OrgCard = ({
  org,
  title,
  className,
  classNameContent,
}: {
  org: Orgmanagerv1Org
  title: ReactNode
  className?: string
  classNameContent?: string
}) => {
  const { setCurrentOrg, orgServiceApi } = useAppContext()
  const [search] = useSearchParams()
  const logoSrc =
    org.logo?.key && `https://s3.amazonaws.com/c.eltoro.com/${org.logo.key}`

  const handleSetCurrentOrg = async () => {
    if (!org.id) return
    await orgServiceApi
      ?.advertisingPlatformServiceGetOrg(org.id)
      .then(setCurrentOrg)
  }

  const searchString = search.toString()

  return (
    <div
      className={classNames(
        'OrgCard bg-gray flex gap-2 rounded px-4 pt-3 pb-2 shadow-sm',
        className,
      )}
    >
      <Avatar name={org.name || ''} src={logoSrc} rounded />
      <div className={`OrgHierarchy__card-content w-full ${classNameContent}`}>
        <span className="RightVerticalPipe text-sm font-medium uppercase">
          {title}
        </span>
        <div
          aria-label={`Go to ${org.name || 'No name'}`}
          data-tooltip="top"
          className="w-max"
        >
          <Link
            className="ChildOrg__link text-lg font-semibold"
            onClick={handleSetCurrentOrg}
            to={`/orgs/${org.id}/org-settings${
              searchString ? `?${searchString}` : ''
            }`}
          >
            <span className="ChildOrg__link-text">
              {truncateMiddle(org.name, 40)}
            </span>
          </Link>
        </div>
        <span className="text-s ml-auto flex items-center justify-end">
          <span className="ChildOrgID__label RightVerticalPipe text-s font-bold uppercase">
            Org ID
          </span>
          <span className="text-m pr-3">{org.id}</span>
          <CopyButton text={org.id || ''} icon="copy" type="Org ID" />
        </span>
        {org.refId && (
          <span className="text-s ml-auto flex items-center justify-end pr-4">
            <span className="ChildOrgID__label RightVerticalPipe text-s font-bold uppercase">
              Ref ID
            </span>
            <span className="text-m pr-3">{org.refId}</span>
          </span>
        )}
      </div>
    </div>
  )
}

export const OrgHierarchy = ({
  org,
  isOpen,
  toggleOpen,
}: {
  org: Orgmanagerv1Org
  isOpen: boolean
  toggleOpen: () => void
}) => {
  const [parentOrg, setParentOrg] = useState<Orgmanagerv1Org>()
  const [status, setStatus] = useState<{
    parent: 'loading' | 'complete'
    children: 'loading' | 'complete'
  }>({
    parent: 'loading',
    children: 'loading',
  })
  const { orgServiceApi } = useAppContext()
  const { name: theme } = useContext(ThemeContext)
  const isDarkMode = theme === 'dark' || theme === 'darkBlue'

  useEffect(() => {
    if (org.parentOrgId) {
      setStatus((prev) => ({ ...prev, parent: 'loading' }))
      orgServiceApi
        ?.advertisingPlatformServiceGetOrg(org.parentOrgId)
        .then(setParentOrg)
        .finally(() => setStatus((prev) => ({ ...prev, parent: 'complete' })))
    } else {
      setParentOrg(undefined)
    }
  }, [org, orgServiceApi])

  const handleFetchData = async ({
    pageSize,
    nextPageToken,
  }: {
    pageSize: number
    nextPageToken?: string
  }) => {
    if (!orgServiceApi) return { data: [], totalItems: 0 }
    setStatus((prev) => ({ ...prev, children: 'loading' }))
    const childOrgs = await orgServiceApi
      .advertisingPlatformServiceListOrgs(
        pageSize, // pageSize
        nextPageToken, // pageToken
        undefined, // orderBy
        org?.id && `parent_org_id = "${org.id}" AND id != "${org.id}"`, // filter
        true,
      )
      .catch(() => {
        showErrorMessage('Error getting child orgs.', '')
        return null
      })
      .finally(() => {
        setStatus((prev) => ({ ...prev, children: 'complete' }))
      })

    if (!childOrgs?.orgs) return { data: [], totalItems: 0 }
    return {
      data: childOrgs.orgs,
      totalItems: childOrgs.totalSize || 0,
      nextPageToken: childOrgs.nextPageToken,
    }
  }

  const pagination = useTokenPagination<Orgmanagerv1Org>(
    {
      pageSize: 6,
      fetchData: handleFetchData,
    },
    [org],
  )

  const { currentPageData: childOrgs } = pagination

  return (
    <SectionCollapse
      title="Org hierarchy"
      editButtons={
        <Button
          iconLeft={<Fa icon="arrow-right-from-bracket" size={1} />}
          to={`/orgs/${org.id}/child-orgs`}
          className={isDarkMode ? '!text-primary' : undefined}
        >
          Manage child orgs
        </Button>
      }
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      className="OrgHierarchy BlockWithBorder mb-16"
      sectionName="Org hierarchy"
    >
      {parentOrg && (
        <OrgCard
          org={parentOrg}
          title={<strong>Parent Org</strong>}
          className="!bg-tint-khaki-100 mt-4"
          classNameContent="OrgHierarchy__card-content parent-org flex items-center"
        />
      )}
      <div
        className={classNames('OrgHierarchy__current-org--wrap flex gap-2', {
          'mt-4': !parentOrg,
          'pl-4': !!parentOrg,
        })}
      >
        {parentOrg && (
          <Fa
            icon="arrow-turn-down-right"
            size={4}
            className={classNames('pt-3', {
              'text-tint-khaki-300': !isDarkMode,
            })}
          />
        )}
        <div className="OrgHierarchy__current-org flex-1">
          <OrgCard
            org={org}
            title={
              <div className="flex items-center">
                <Fa icon="star" size={1} className="text-warning-300" />
                <strong>Current Org</strong>
              </div>
            }
            className={classNames({
              '!bg-base-info-50 !border-base-info-50': !parentOrg,
            })}
            classNameContent="OrgHierarchy__card-content current-org flex items-center"
          />
          {childOrgs.length > 0 ? (
            <div
              className={classNames('OrgHierarchy__child-orgs', {
                'opacity-30': status.children === 'loading',
              })}
            >
              <Fa
                icon="circle-arrow-down"
                size={4}
                className={classNames('mt-2 mb-2', {
                  'text-tint-gray-50': !isDarkMode,
                  'text-tint-gray-300': isDarkMode,
                })}
              />
              <div
                className={classNames({
                  'ChildHierarchy__org-list desktopsmall:grid-cols-1 grid grid-cols-2 gap-2':
                    childOrgs.length > 1,
                })}
              >
                {childOrgs.map((child) => (
                  <OrgCard
                    key={child.id}
                    title={
                      <strong className="ChildHierarchy__org-label pt-1">
                        Child org name
                      </strong>
                    }
                    org={child}
                    classNameContent="OrgHierarchy__card-content child-org flex flex-col"
                  />
                ))}
              </div>
            </div>
          ) : null}
          {(org.childOrgs || [])?.length > 10 && (
            <div className="OrgHierarchy__child-pagination mt-3 flex justify-center">
              <TablePagination
                pagination={pagination}
                pageSizeOptions={[
                  { value: '6', label: '6 per page' },
                  { value: '12', label: '12 per page' },
                  { value: '24', label: '24 per page' },
                ]}
              />
            </div>
          )}
        </div>
      </div>
    </SectionCollapse>
  )
}
