import { useEffect, useState } from 'react'
import {
  Button,
  Fa,
  showErrorMessage,
  TextHeader,
  LoadingBar,
  TextInput,
  showSuccessMessage,
  CharacterCounter,
  Tooltip,
} from '@eltoro-ui/components'
import { Orgmanagerv1CPM, Orgmanagerv1Contact } from 'next-gen-sdk'
import { Link, useSearchParams } from 'react-router-dom'
import { dayjs } from 'Tools/dateUtils'
import {
  Loading,
  PageContent,
  CPMTable,
  PoliticalToggle,
  V2SyncWarning,
  OrgNotes,
} from 'Components'
import { useAppContext } from 'Contexts'
import {
  ErrorMessage,
  getTypeMoneyCost,
  isValidCharLength,
  separatePoliticalCPMs,
  styleTailwind,
  truncateMiddle,
  v2SyncPrompt,
  validCheck,
} from 'Helpers'
import { ETIcon } from 'Assets'
import {
  ContactBlock,
  LogoBlock,
  SalesExecPopover,
  SalesExecTable,
  UpdateNameModal,
  MinimumImpressions,
  OrgHierarchy,
  SectionCollapse,
  TOSList,
} from './components'
import { CPMObjectType } from 'types'
import { OrgUsers } from './components/OrgUsers'
import { emptyCPMs } from './emptyCPMs'
import { createCPM } from 'Requests'
import './OrgSettingsPage.scss'
import classNames from 'classnames'

const NotEnabledTag = () => (
  <div className="NotEnabledTag relative">
    <span className="ribbon bottom-left flex">
      <Fa icon="circle-exclamation" size={1} />
      <span className="text-s py-1 leading-none">Not enabled on org</span>
    </span>
  </div>
)

export const DetailItem = styleTailwind(
  'div',
  'DetailItem RightVerticalPipe last:border-none text-center flex flex-1 flex-wrap justify-center items-center my-1 py-2',
)

export const OrgSettingsPage = () => {
  const [loading, setLoading] = useState<{
    salesContact: boolean
    operationsContact: boolean
    commGroup: boolean
    users: boolean
    cpms: boolean
  }>({
    salesContact: false,
    operationsContact: false,
    commGroup: false,
    users: true,
    cpms: false,
  })
  const [sectionToggle, setSectionToggle] = useState({
    userTable: true,
    salesExec: true,
    cpmTable: true,
    orgHierarchy: true,
  })
  const [inputCpms, setInputCpms] = useState<CPMObjectType | undefined>(
    emptyCPMs,
  )
  const [editingCpms, setEditingCpms] = useState(false)
  const [hasCpmEdits, setHasCpmEdits] = useState(false)
  const [showNameModal, setShowNameModal] = useState(false)
  const [editRefId, setEditRefId] = useState(false)
  const [showPoliticalPricing, setShowPoliticalPricing] = useState(false)
  const [loadingRefId, setLoadingRefIdUpdate] = useState(false)
  const {
    currentOrg,
    setCurrentOrg,
    orgServiceApi,
    isAdmin,
    groups,
    roles,
  } = useAppContext()
  const [search] = useSearchParams()
  const [refId, setRefId] = useState<string | undefined>('')

  const handleLoading = (type: string, isLoading: boolean) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [type]: isLoading,
    }))
  }

  const handleAPICallWithLoading = (
    call: Promise<Orgmanagerv1Contact> | undefined,
    handleIsLoading: (isLoading: boolean) => void,
  ) => {
    handleIsLoading(true)
    if (call) {
      call
        .then(async (res) => {
          if (currentOrg?.id && orgServiceApi) {
            const updatedOrg = await orgServiceApi.advertisingPlatformServiceGetOrg(
              currentOrg.id,
            )
            setCurrentOrg(updatedOrg)
          }
          handleIsLoading(false)
        })
        .catch(() => {
          handleIsLoading(false)
          showErrorMessage(
            'Error updating org',
            'There was an error saving your org. Please try again. If the error persists, please submit a support request.',
          )
        })
    }
  }

  const handleContactUpdate = (newContact: Orgmanagerv1Contact) => {
    const { id } = newContact
    if (!(currentOrg?.id && id)) return
    handleAPICallWithLoading(
      orgServiceApi?.advertisingPlatformServiceUpdateContact(
        currentOrg.id,
        id,
        newContact,
      ),
      (isLoading) =>
        newContact.type &&
        handleLoading(`${newContact.type}Contact`, isLoading),
    )
  }

  const handleCreateContact = (
    newContact: Orgmanagerv1Contact,
    type: string,
  ) => {
    if (!currentOrg?.id) return
    handleAPICallWithLoading(
      orgServiceApi?.advertisingPlatformServiceCreateContact(currentOrg.id, {
        ...newContact,
        type,
      }),
      (isLoading) => handleLoading(type, isLoading),
    )
  }

  const handleRemoveContact = (contactId: string, type: string) => {
    if (!currentOrg?.id) return
    handleAPICallWithLoading(
      orgServiceApi?.advertisingPlatformServiceDeleteContact(
        currentOrg.id,
        contactId,
      ),
      (isLoading) => handleLoading(type, isLoading),
    )
  }

  const handleUpdateOrgCpms = async () => {
    handleLoading('cpms', true)
    // for each cpm, determine if it has changed
    const changedCpms = Object.entries(inputCpms || {}).reduce(
      (acc: Orgmanagerv1CPM[], [key, cpm]) => {
        if (
          currentOrg?.cpms?.[key] &&
          getTypeMoneyCost(currentOrg.cpms[key]) !== getTypeMoneyCost(cpm)
        ) {
          return [...acc, { ...currentOrg.cpms[key], ...cpm }]
        }
        if (!cpm.id && getTypeMoneyCost(cpm)) {
          return [...acc, cpm]
        }
        return acc
      },
      [],
    )
    await Promise.all(
      changedCpms.map((cpm) => {
        if (!currentOrg?.id || !orgServiceApi) return Promise.resolve({})
        if (!cpm.id) {
          return createCPM(cpm as CPMObjectType, currentOrg.id)
        }
        return orgServiceApi.advertisingPlatformServiceUpdateCPM(
          currentOrg.id,
          cpm.id,
          cpm,
        )
      }),
    )
      .then(async () => {
        // refresh the org
        if (!currentOrg?.id || !orgServiceApi) return
        const updatedOrg = await orgServiceApi.advertisingPlatformServiceGetOrg(
          currentOrg.id,
        )
        setCurrentOrg(updatedOrg)
      })
      .catch(() =>
        showErrorMessage(
          'Error updating org CPMs',
          'There was an error saving your org CPMs. Please try again. If the error persists, please submit a support request.',
        ),
      )
      .finally(() => handleLoading('cpms', false))
  }
  const handleUpdateOrgRefId = async () => {
    if (!currentOrg?.id) return
    setLoadingRefIdUpdate(true)
    orgServiceApi
      ?.advertisingPlatformServiceUpdateOrg(
        currentOrg.id,
        { refId: refId },
        'ref_id',
      )
      .then((updatedOrg) => {
        showSuccessMessage('Ref Id Updated', '')
        setEditRefId(false)
        setCurrentOrg(updatedOrg)
      })
      .catch(() => {
        showErrorMessage('Ref Id update failed', '')
      })
      .finally(() => {
        setLoadingRefIdUpdate(false)
      })
  }

  useEffect(() => {
    if (currentOrg?.cpms) {
      setInputCpms({ ...emptyCPMs, ...currentOrg.cpms })
      setHasCpmEdits(false)
    }
    if (currentOrg?.refId) {
      setRefId(currentOrg.refId)
    }
  }, [currentOrg?.cpms, currentOrg?.refId])

  // Gets collapsed section state from url
  useEffect(() => {
    const isOpen = (val: string | null) => val !== 'false'
    const userTable = isOpen(search.get('Org Users'))
    const salesExec = isOpen(search.get('Sales Executives'))
    const cpmTable = isOpen(search.get('CPM Table'))
    const orgHierarchy = isOpen(search.get('Org hierarchy'))

    setSectionToggle({
      userTable,
      salesExec,
      cpmTable,
      orgHierarchy,
    })
  }, [search])

  const getContact = (type: string) =>
    currentOrg?.contacts?.filter((contact) => contact.type === type)

  const salesContact = getContact('sales')?.[0]
  const operationsContact = getContact('operations')?.[0]

  const sortedExecutives = currentOrg?.commissionGroup?.users?.sort((a, b) => {
    if (!a.lastName || !b.lastName) return 0
    return a.lastName.localeCompare(b.lastName)
  })

  const salesContactNames = [...(sortedExecutives || [])]?.reduce(
    (acc: string, exec, index) => {
      const stringName = `${exec.firstName} ${exec.lastName}`
      if (index === 0) return stringName
      if (index === (sortedExecutives || []).length - 1) {
        if ((sortedExecutives || []).length < 3)
          return acc + ' or ' + stringName
        return acc + ', or ' + stringName
      }
      return acc + ', ' + stringName
    },
    '',
  )

  const cpmOnChange = editingCpms
    ? (newCpm: CPMObjectType) => {
        if (!hasCpmEdits) setHasCpmEdits(true)
        setInputCpms((prev) => {
          if (prev) {
            return { ...prev, ...newCpm }
          }
          return newCpm
        })
      }
    : undefined

  const { nonPoliticalCPMs, politicalCPMs } = separatePoliticalCPMs(
    inputCpms || {},
  )

  const isSales = !!groups?.find(
    (g) => g === '/Sales/Internal' || g === '/Sales/External',
  )

  const needsV2Confirmation =
    currentOrg?.lastSyncTime &&
    new Date(currentOrg.lastSyncTime).getFullYear() > 2023

  return (
    <PageContent className="OrgSettingsPage mb-12">
      <h1 className="OrgSettingsPage__header flex items-center pb-5">
        <span
          className={`text-3xl font-light uppercase ${
            currentOrg || !loading ? 'RightVerticalPipe' : ''
          }`}
        >
          Org settings
        </span>
        {currentOrg || !loading ? (
          <>
            <span className="OrgSettingsPage__header-name whitespace-nowrap text-3xl">
              {truncateMiddle(currentOrg?.name, 30)}
            </span>
            <div className={`relative flex flex-row gap-2 pl-4`}>
              {!editRefId ? (
                <span className={`${!editRefId ? 'pl-2 text-sm' : ''} m-auto`}>
                  {loadingRefId ? (
                    <span className="pt-4">Saving</span>
                  ) : (
                    <div className="flex flex-row justify-start pt-2 font-bold">
                      {currentOrg?.refId && (
                        <span className=" whitespace-nowrap">Ref Id#:</span>
                      )}
                      <span>
                        {(currentOrg?.refId && (
                          <span>{currentOrg?.refId}</span>
                        )) || <span className="m-auto">Add Ref Id</span>}
                      </span>
                    </div>
                  )}
                </span>
              ) : (
                <span className="flex flex-col">
                  <TextInput
                    type="text"
                    value={refId || ''}
                    onChange={(e) => setRefId(e.target.value)}
                    placeholder="Add Ref Id"
                    id="refId"
                    className="w-full border-b-2 border-gray-300 bg-transparent pb-0 pl-1 pt-2 text-lg text-gray-800 outline-none transition-colors duration-200 placeholder:text-gray-400 focus:border-blue-500 focus:outline-none"
                    disabled={!editRefId}
                    maxLength={30}
                  />
                  <CharacterCounter
                    fieldName={'refId'}
                    max={30}
                    text={refId || ''}
                  />
                </span>
              )}
              <div className="m-auto flex justify-center pt-2 pr-1">
                <Button
                  iconOnly={
                    loadingRefId ? (
                      <Fa
                        className={classNames({
                          'animate-spin': loadingRefId,
                          'm-auto': true,
                        })}
                        icon={'circle-notch'}
                        size={0.5}
                      />
                    ) : (
                      <Fa icon={editRefId ? 'save' : 'pencil'} size={0.5} />
                    )
                  }
                  onClick={() => {
                    setEditRefId(!editRefId)
                    if (editRefId) handleUpdateOrgRefId()
                  }}
                  disabled={roles?.includes('nextgen_read_only')}
                  ariaLabel={editRefId ? 'Save changes' : 'Edit Ref Id'}
                  dataTooltip="left"
                  type="button"
                  size="s"
                />
                {editRefId && (
                  <Button
                    iconOnly={<Fa icon="xmark" size={0.5} />}
                    onClick={() => {
                      setEditRefId(false)
                      setRefId(currentOrg?.refId) // reset
                    }}
                    ariaLabel="Cancel"
                    dataTooltip="left"
                    size="s"
                  />
                )}
              </div>
            </div>

            <Button
              className="ml-auto -mr-3 max-w-max"
              onClick={() =>
                v2SyncPrompt(() => setShowNameModal(true), currentOrg)
              }
            >
              Change org name
            </Button>
          </>
        ) : null}
      </h1>

      {currentOrg ? (
        <>
          <div className="OrgSettingsPage__content laptop:flex-col-reverse flex gap-8">
            <div className="OrgSettingsPage__main-col mt-2 flex flex-1 flex-col gap-6">
              {/* -------------- v2 sync -------------- */}
              {needsV2Confirmation && <V2SyncWarning currentOrg={currentOrg} />}
              {isAdmin && <OrgNotes />}
              {/* -------------- details -------------- */}
              <div className="OrgSettingsPage__details bg-primary-50 border-primer border-thin rounded-lg">
                <div className="DetailsSection mobile:flex-wrap flex flex-1 items-center justify-center bg-transparent">
                  <DetailItem>
                    <div className="DetailSubheader">
                      {currentOrg.billableOrgId === currentOrg.id ||
                      !currentOrg.billableOrgId
                        ? 'Billable'
                        : 'Non-billable'}
                    </div>
                    <div className="DetailInfo text-basetext">Org Type</div>
                  </DetailItem>
                  {currentOrg.createTime && (
                    <DetailItem>
                      <div className="DetailSubheader">
                        {dayjs.tz(currentOrg.createTime).format('MM/DD/YYYY')}
                      </div>
                      <div className="DetailInfo text-basetext">
                        Date Created
                      </div>
                    </DetailItem>
                  )}
                  {(() => {
                    if (loading.commGroup) return <LoadingBar />
                    if (!(sortedExecutives || []).length)
                      return (
                        <DetailItem>
                          <p>
                            <strong>No Sales Executives Found</strong>
                          </p>
                          <p>El Toro Sales Executive</p>
                        </DetailItem>
                      )
                    return sortedExecutives?.slice(0, 2).map((user) => (
                      <DetailItem key={user.id}>
                        <SalesExecPopover user={user} key={user.id} />
                      </DetailItem>
                    ))
                  })()}
                  <DetailItem>
                    <div className="DetailSubheader">
                      {currentOrg.childOrgs?.length || 0}
                    </div>
                    <div className="Child-org-link relative">
                      <Link
                        to="../child-orgs"
                        relative="path"
                        className="DetailInfo text-base-info font-bold transition-all hover:cursor-pointer hover:underline"
                      >
                        Child orgs
                      </Link>
                      <span className="absolute -right-6 top-0">
                        <span
                          data-tooltip="bottom"
                          aria-label="Child orgs can be used by a parent org (such as a reseller) to give portal access to child orgs (such as a reseller's clients). Child orgs are also a great tool for a parent org to organize the campaigns, order lines, and billing of their child orgs."
                        >
                          <Fa
                            icon="info-circle"
                            className="!text-base-info-200 hover:cursor-pointer"
                            size={1}
                          />
                        </span>
                      </span>
                    </div>
                  </DetailItem>
                </div>
              </div>

              {/* -------------- users table -------------- */}
              <OrgUsers
                isOpen={sectionToggle.userTable}
                toggleOpen={() =>
                  setSectionToggle((prev) => ({
                    ...prev,
                    userTable: !prev.userTable,
                  }))
                }
              />
              {/* -------------- sales executive table -------------- */}
              {(isAdmin ||
                (currentOrg.commissionGroup?.users || []).length > 2) && (
                <SalesExecTable
                  existingCommGroup={currentOrg.commissionGroup}
                  loading={loading.commGroup}
                  setLoading={(bool) => handleLoading('commGroup', bool)}
                  isOpen={sectionToggle.salesExec}
                  toggleOpen={() =>
                    setSectionToggle((prev) => ({
                      ...prev,
                      salesExec: !prev.salesExec,
                    }))
                  }
                />
              )}
              {/* -------------- pricing structure -------------- */}
              <SectionCollapse
                title={
                  <>
                    <span className="Section__digital-products--title RightVerticalPipe">
                      Digital products
                    </span>
                    <span className="font-normal">
                      CPM (Cost per thousand impressions)
                    </span>
                  </>
                }
                editButtons={
                  isAdmin || isSales ? (
                    <div className="CPM__table--edit-buttons flex gap-2">
                      <Button
                        onClick={() => {
                          if (editingCpms) {
                            setInputCpms({ ...emptyCPMs, ...currentOrg.cpms })
                            setEditingCpms(false)
                          } else {
                            v2SyncPrompt(() => setEditingCpms(true), currentOrg)
                          }
                        }}
                        iconLeft={
                          <Fa icon={editingCpms ? 'xmark' : 'edit'} size={1} />
                        }
                      >
                        {editingCpms ? 'Done' : 'Edit'}
                      </Button>
                      {editingCpms && (
                        <Button
                          onClick={handleUpdateOrgCpms}
                          disabled={!hasCpmEdits}
                          iconLeft={<Fa icon="save" size={1} />}
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  ) : null
                }
                isOpen={sectionToggle.cpmTable}
                toggleOpen={() =>
                  setSectionToggle((prev) => ({
                    ...prev,
                    cpmTable: !prev.cpmTable,
                  }))
                }
                sectionName="CPM Table"
              >
                {loading.cpms && (
                  <div className="OrgSettingsPage__loading-cpm bg-base pointer-events-none absolute top-0 bottom-0 right-0 left-0 overflow-clip rounded-xl p-2 opacity-60">
                    <Fa
                      className="text-tint-khaki"
                      icon="spinner"
                      size={6}
                      animate="spin"
                    />
                  </div>
                )}
                <div className="CPMTable__container my-2 mt-4 flex flex-col gap-4">
                  {showPoliticalPricing && (
                    <TextHeader
                      type={4}
                      className="text-grey-500 animate-slidedown"
                    >
                      CPMs
                    </TextHeader>
                  )}
                  {/* Non-political CPM table */}
                  <CPMTable
                    inputCpms={nonPoliticalCPMs}
                    onChange={cpmOnChange}
                    isPolitical={false}
                  />

                  {/* Political CPM table */}
                  {showPoliticalPricing && (
                    <div className="animate-slidedown flex flex-col gap-4">
                      <TextHeader type={4} className="text-grey-500">
                        Limited Inventory(Political) CPMs
                      </TextHeader>
                      <CPMTable
                        inputCpms={politicalCPMs}
                        onChange={cpmOnChange}
                        isPolitical
                      />
                    </div>
                  )}
                </div>
                <div className="OrgSettings_cpm-toggle mt-2 flex items-center justify-between">
                  <MinimumImpressions />
                  <PoliticalToggle
                    isChecked={showPoliticalPricing}
                    onChange={setShowPoliticalPricing}
                  />
                </div>

                {/* TODO: determine if these are activated on the org */}

                <div className="PrintBasedProduct__list--wrap flex w-auto flex-1 flex-col">
                  <TextHeader className="mb-4 mt-8">
                    Print-based products
                  </TextHeader>
                  <div className="PrintBasedProduct__list desktopsmall:flex-col desktopsmall:gap-1 flex w-auto max-w-fit gap-4">
                    {[
                      {
                        icon: <ETIcon icon="web-to-home" />,
                        name: 'Web-To-Home',
                        text: `Web-To-Home enables you to retarget customers who visited your website with a direct mail piece. Contact your Sales Account Manager ${
                          salesContactNames ? salesContactNames : ''
                        } for pricing information`,
                      },
                      {
                        icon: <ETIcon icon="vr-direct" />,
                        name: 'VR-To-Direct Mail',
                        text: `Capture mobile advertising IDs (MAIDs) seen at locations of interest and send them mail pieces. Contact your Sales Account Manager ${
                          salesContactNames ? salesContactNames : ''
                        } for pricing information`,
                      },
                    ].map(({ icon, name, text }) => (
                      <div
                        key={name}
                        className="OrgSettingsPage__print-based-product bg-primary-50 desktopsmall:items-center desktopsmall:w-full mb-1 flex w-1/2 max-w-max items-start justify-start gap-1 py-3 pr-3"
                      >
                        <div className="OrgSettingsPage__product-label ml-3 flex w-1/4 flex-col">
                          <div className="desktopsmall:items-center desktopsmall:pb-2 desktopsmall:mt-0 mt-2 flex flex-1 items-start gap-1">
                            {icon}
                            <span className="text-lg font-bold">{name}</span>
                          </div>
                          <NotEnabledTag />
                        </div>
                        <span className="text-ml w-2/3 max-w-fit flex-1">
                          {text}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </SectionCollapse>
              {/* -------------- org hierarchy -------------- */}
              <OrgHierarchy
                org={currentOrg}
                isOpen={sectionToggle.orgHierarchy}
                toggleOpen={() =>
                  setSectionToggle((prev) => ({
                    ...prev,
                    orgHierarchy: !prev.orgHierarchy,
                  }))
                }
              />
            </div>
            <div className="OrgSettingsPage__right-col laptop:w-full relative top-14 flex flex-col">
              {/* -------------- ORG LOGO -------------- */}
              <LogoBlock currentOrg={currentOrg} />
              <div className="OrgSettingsPage__contact-wrap BlockWithBorder laptop:flex-row laptop:justify-around laptop:gap-4 flex flex-col py-8">
                {/* -------------- OPERATIONS CONTACT -------------- */}
                <div className="OrgSettingsPage__contact laptop:flex-1">
                  <ContactBlock
                    type="operations"
                    contact={operationsContact}
                    onUpdate={handleContactUpdate}
                    onCreate={handleCreateContact}
                    onRemove={(contactId) =>
                      handleRemoveContact(contactId, 'operations')
                    }
                    isLoading={loading.operationsContact}
                  />
                </div>
                {/* -------------- SALES CONTACT -------------- */}
                <div className="OrgSettingsPage__contact laptop:flex-1 border-none pt-4">
                  <ContactBlock
                    type="sales"
                    contact={salesContact}
                    onUpdate={handleContactUpdate}
                    onCreate={handleCreateContact}
                    onRemove={(contactId) =>
                      handleRemoveContact(contactId, 'sales')
                    }
                    isLoading={loading.salesContact}
                  />
                </div>
              </div>
              {/* -------------- TOS LIST -------------- */}
              <div className="OrgSettingsPage__tos-wrap BlockWithBorder laptop:flex-row laptop:justify-around laptop:gap-4 flex flex-col">
                <TOSList />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
      {showNameModal && currentOrg && (
        <UpdateNameModal
          closeModal={() => setShowNameModal(false)}
          org={currentOrg}
        />
      )}
    </PageContent>
  )
}
