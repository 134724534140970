import { useState } from 'react'
import { Fa } from '@eltoro-ui/components'
import { Targetjobservicev1Audience, V2Target } from 'next-gen-sdk'
import classNames from 'classnames'
import { styleTailwind } from 'Helpers'
import { InfoPopover, LinkButton } from 'Components'
import { ETIcon } from 'Assets'
import { useAppContext } from 'Contexts'
import { downloadAudienceSelectedFile, downloadFile } from 'Requests'
import './SubJobBreakdown.scss'
import { MatchCap } from '../MatchCap'

const JobItem = styleTailwind('div', 'text-s flex mb-2')
const IconContainer = styleTailwind(
  'div',
  'text-primary-500 mr-2 min-w-[2.5rem] flex flex-col items-center justify-center',
)

const AdminJobWrapper = ({
  subJob,
  hasDeployments,
  target,
  children,
}: {
  subJob: Targetjobservicev1Audience
  hasDeployments?: boolean
  target?: V2Target
  children: React.ReactNode
}) => {
  const [open, setOpen] = useState(false)
  const { isAdmin, isLeadershipOrDev } = useAppContext()

  const processNotCompleted = subJob.result?.processCompleted !== 1

  if (!isAdmin) return <>{children}</>

  return (
    <div
      className={classNames('mb-2 rounded py-1', {
        'bg-grey-50': open,
      })}
    >
      {children}

      <div className="flex items-center justify-center gap-2">
        <div
          data-tooltip="top"
          aria-label={
            processNotCompleted
              ? 'This append file is not available.'
              : undefined
          }
        >
          <LinkButton
            onClick={() => target && downloadFile({ target, audience: subJob })}
            text="Download Append"
            dataToolTip="top"
            disabled={processNotCompleted}
          />
        </div>
        {isLeadershipOrDev && (
          <>
            <p>|</p>
            <LinkButton
              onClick={() => downloadAudienceSelectedFile(subJob)}
              text="Download Selected"
              dataToolTip="top"
              disabled={processNotCompleted}
            />
          </>
        )}
      </div>
      <div className="w-full flex justify-center mt-2">
        <LinkButton
          onClick={() => setOpen(!open)}
          text={open ? 'Close Match Cap' : 'Match Cap'}
          dataToolTip="top"
        />
      </div>
      {open && (
        <MatchCap
          audience={subJob}
          hasDeployments={hasDeployments}
          className="SubJobBreakdown__match-cap animate-slidedown px-2 pt-3"
        />
      )}
    </div>
  )
}

export const SubJobBreakdown = ({
  audience,
  hasDeployments,
  target,
}: {
  audience: Targetjobservicev1Audience
  hasDeployments?: boolean
  target?: V2Target
}) => {
  const mappedDeviceIDsJob = audience.audiences?.find(
    (subJob) => subJob.subType === 'AUDIENCE_SUB_TYPE_DEVICES_WITH_HOMES',
  )
  const mappedHomesJob = audience.audiences?.find(
    (subJob) => subJob.subType === 'AUDIENCE_SUB_TYPE_HOMES',
  )
  const otherDeviceIDsInHomeJob = audience.audiences?.find(
    (subJob) =>
      subJob.subType === 'AUDIENCE_SUB_TYPE_DEVICES_IN_HOMES_NOT_SEEN',
  )
  const unmappedDeviceIDsJob = audience.audiences?.find(
    (subJob) => subJob.subType === 'AUDIENCE_SUB_TYPE_DEVICES_WITHOUT_HOMES',
  )

  if (
    audience.status === 'AUDIENCE_STATUS_RUNNING' ||
    audience.status === 'AUDIENCE_STATUS_QUEUED'
  )
    return (
      <div className="SubJobBreakdown__running flex animate-pulse items-center justify-center">
        <Fa icon="cog" className="text-grey-400" size={1} />
        &nbsp;In Quote
      </div>
    )

  return (
    <div className="SubJobBreakdown">
      <div className="text-m mb-2 flex items-center font-bold">
        Audiences
        <div className="text-grey-300">
          <InfoPopover
            icon="circle-question"
            content={
              <div>
                MAID stands for Mobile Advertiser ID, which is a unique ID
                assigned to a mobile device.
              </div>
            }
          />
        </div>
      </div>
      {mappedDeviceIDsJob?.result && (
        <AdminJobWrapper
          subJob={mappedDeviceIDsJob}
          hasDeployments={hasDeployments}
          target={target}
        >
          <JobItem>
            <IconContainer className="relative">
              <Fa icon="mobile-screen-button" size={5} className="IconStack" />
              <Fa
                icon="circle-check"
                size={1}
                className="IconStack text-success-500 absolute bottom-1"
              />
            </IconContainer>
            <div>
              <p>
                <strong>
                  {mappedDeviceIDsJob.result.matched || 0} Mapped Device IDs
                </strong>
              </p>
              <p>Seen at location with known households</p>
            </div>
            <Fa
              icon="arrow-turn-down"
              size={2}
              className="text-grey-200 self-end"
            />
          </JobItem>
        </AdminJobWrapper>
      )}
      {mappedHomesJob?.result && (
        <AdminJobWrapper
          subJob={mappedHomesJob}
          hasDeployments={hasDeployments}
          target={target}
        >
          <JobItem>
            <IconContainer>
              <ETIcon className="scale-115" icon="homes" />
            </IconContainer>
            <div>
              <p>
                <strong>
                  {mappedHomesJob.result.matched || 0} Mapped Homes
                </strong>
              </p>
              <p>Unique households of device IDs</p>
            </div>
            <Fa
              icon="arrow-turn-down"
              size={2}
              className="text-grey-200 self-end"
            />
          </JobItem>
        </AdminJobWrapper>
      )}
      {otherDeviceIDsInHomeJob?.result && (
        <AdminJobWrapper
          subJob={otherDeviceIDsInHomeJob}
          hasDeployments={hasDeployments}
          target={target}
        >
          <JobItem>
            <IconContainer>
              <Fa icon="laptop-mobile" size={4} type="duotone" />
            </IconContainer>
            <div>
              <p>
                <strong>
                  {otherDeviceIDsInHomeJob.result.matched || 0} Other Device IDs
                  in Home
                </strong>
              </p>
              <p>Device IDs only seen within mapped households</p>
            </div>
          </JobItem>
        </AdminJobWrapper>
      )}
      {unmappedDeviceIDsJob?.result && (
        <AdminJobWrapper
          subJob={unmappedDeviceIDsJob}
          hasDeployments={hasDeployments}
          target={target}
        >
          <JobItem>
            <IconContainer className="relative">
              <Fa icon="mobile-screen-button" size={5} className="IconStack" />
              <Fa
                icon="circle-question"
                size={1}
                className="IconStack text-warning-400 absolute bottom-1"
              />
            </IconContainer>
            <div>
              <p>
                <strong>
                  {unmappedDeviceIDsJob.result.matched} Unmapped Device IDs
                </strong>
              </p>
              <p>Seen at location without mapped households</p>
            </div>
          </JobItem>
        </AdminJobWrapper>
      )}
    </div>
  )
}
