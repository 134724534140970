import React from 'react'
import { Avatar, TableColumnType } from '@eltoro-ui/components'
import { getDateBreakdown } from '@eltoro-ui/helpers'
import { Link } from 'react-router-dom'
import { Orgmanagerv1Org } from 'next-gen-sdk'
import { ChildOrgTableRow } from 'types'
import { EmptyField, TableRowLoadingIcon } from 'Components'
import { useAppContext } from 'Contexts'

export const OrgNameRow = ({ org }: { org: Orgmanagerv1Org }) => {
  const { setCurrentOrg } = useAppContext()
  return (
    <div className="ChildOrgTable__name-row flex max-w-md items-center gap-2 p-1">
      {(() => {
        if (org.logo?.key) {
          return (
            <Avatar
              src={`https://s3.amazonaws.com/c.eltoro.com/${org.logo.key}`}
              rounded
            />
          )
        }
        return <Avatar name={org.name || ''} rounded />
      })()}
      <Link onClick={() => setCurrentOrg(org)} to="/campaigns/dashboard">
        {org.name}
      </Link>
    </div>
  )
}

export const ChildOrgTableColumns: TableColumnType<ChildOrgTableRow>[] = [
  {
    path: ['org', 'name'],
    filterPath: 'name',
    label: 'Child Org Name',
    filterOn: 'string',
    RowCell: ({ org }) => <OrgNameRow org={org} />,
  },
  {
    path: 'dateOfLastDeployedOrderLine',
    label: 'Date of last deployed order line',
    removeFilter: true,
    removeSort: true,
    RowCell: ({ dateOfLastDeployedOrderLine, loading }) => {
      if (loading) return <TableRowLoadingIcon />
      if (dateOfLastDeployedOrderLine) {
        const dateInfo = getDateBreakdown(dateOfLastDeployedOrderLine)
        return `${dateInfo?.month}/${dateInfo?.date}/${dateInfo?.year}`
      }
      return <EmptyField />
    },
  },
  {
    path: 'servingCampaigns',
    label: 'Serving campaigns',
    removeFilter: true,
    removeSort: true,
    RowCell: ({ servingCampaigns, loading }) => {
      if (loading) return <TableRowLoadingIcon />
      if (servingCampaigns === undefined) return <EmptyField />
      return servingCampaigns
    },
  },
  {
    path: 'servingOrderLines',
    label: 'Serving order lines',
    removeFilter: true,
    removeSort: true,
    RowCell: ({ servingOrderLines, loading }) => {
      if (loading) return <TableRowLoadingIcon />
      if (servingOrderLines === undefined) return <EmptyField />
      return servingOrderLines
    },
  },
  {
    path: 'spend',
    label: 'Total spent - YTD',
    removeFilter: true,
    removeSort: true,
    RowCell: ({ spend, loading }) => {
      if (loading) return <TableRowLoadingIcon />
      if (spend === undefined) return <EmptyField />
      return `$${spend?.toLocaleString()}`
    },
  },
  {
    path: ['org', 'refId'],
    label: 'Ref Id',
    removeFilter: true,
    removeSort: true,
    RowCell: (row) => {
      if (row.org.refId === undefined) return <EmptyField />
      return row.org.refId
    },
  },
  {
    path: ['org', 'id'],
    filterPath: 'id',
    label: 'ID',
    filterOn: 'string',
  },
]
